import React from 'react';
import ReactPlayer from 'react-player';

const S3Video = ({ url }) => (
  <ReactPlayer
    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    url={url}
    playing={true}
    muted={false}
    loop={false}
    controls={true}
    width={'100%'}
    height={'auto'}
  />
);
export default S3Video;
