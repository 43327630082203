import React from 'react';
import { graphql, Link } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { GatsbyImage } from 'gatsby-plugin-image';
import S3Video from '../components/s3-video'
import * as style from '../styles/portfolio-details.module.scss'
import CloseIcon from '../images/common/ic_close.png' 

const PortfolioDetails = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { type } = data.markdownRemark.frontmatter;

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div className={style.portfolioModal}>
          {/* {modal ? (
            <>
              <Link to={closeTo}>Close</Link>
              <h1>{title}</h1>
            </>
          ) : (
            <>
              <header>
                <h1>{title}</h1>
              </header>
              <Link to="/">Go back to the homepage</Link>
            </>
          )} */}
          <div className={style.portfolioModalHeader}>
            <h1>{title}</h1>
            <Link to="/" className={style.btnClose}><img src={CloseIcon} alt='close' /></Link>
          </div>
          {
            type === 'image' &&
            <> 
              <GatsbyImage
                image={
                  data.markdownRemark.frontmatter.sourceImg.childImageSharp.gatsbyImageData
                }
                alt={data.markdownRemark.frontmatter.title}
              />
            </>
          }
          {
            type === 'video' &&
            <> 
              <S3Video url={data.markdownRemark.frontmatter.sourceVideo} />
            </>
          }
        </div>
      )}
    </ModalRoutingContext.Consumer>
  );
};
export default PortfolioDetails;

export const query = graphql`
  query ($name: String!, $image: Boolean!, $video: Boolean!) {
    markdownRemark(frontmatter: {name: {eq: $name}}) {
      html
      frontmatter {
        title
        type
        sourceImg @include(if: $image) {
          childImageSharp {
            gatsbyImageData
          }
        }
        sourceVideo @include(if: $video)
      }
    }
  }
`;
